<template>
  <div>
    <template v-for="(item, index) in bidSecurityDeposits">
      <el-card :key="index" style="margin-top: 5px">
        <div slot="header">
          <div class="header-top">
            {{ item.typeTwo | dict(bidTyleList) }}：<span class="input-money">
              {{ item.sumCost | thousands }}</span
            >
          </div>
        </div>

        <el-table :data="item.SecurityDepositsList" border style="width: 100%">
          <el-table-column type="index" label="序号" align="center" width="70"> </el-table-column>
          <el-table-column prop="costType" align="center" label="单据类型" width="130">
          </el-table-column>
          <el-table-column prop="expenseNumber" align="center" label="单据编号" width="180">
          </el-table-column>
          <el-table-column prop="applicantName" align="center" label="申请人" width="110">
          </el-table-column>
          <el-table-column
            prop="companyType"
            align="center"
            label="费用所属单位"
            :show-overflow-tooltip="false"
            width="110"
          >
            <template slot-scope="scope">
              {{ scope.row.companyType | dict(companyType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请时间"
            :show-overflow-tooltip="false"
            width="110"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            align="center"
            label="保证金金额"
            :show-overflow-tooltip="false"
            width="130"
          >
            <template slot-scope="scope"> {{ scope.row.cost | thousands }}元 </template>
          </el-table-column>
          <el-table-column
            prop="depositReturn"
            align="center"
            label="是否退回"
            :show-overflow-tooltip="false"
            width="90"
          >
            <template slot-scope="scope">
              <span :style="{ color: scope.row.notReturn ? 'red' : '' }">{{
                scope.row.depositReturn == 1 ? '是' : '否'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="estimatedRefundDate"
            align="center"
            label="预计退回时间"
            :show-overflow-tooltip="false"
            width="110"
          >
            <template slot-scope="scope">
              {{ scope.row.estimatedRefundDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="depositReturn"
            align="center"
            label="退回时间"
            :show-overflow-tooltip="false"
            width="110"
          >
            <template slot-scope="scope">
              {{ scope.row.returnDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            align="center"
            label="特殊情况说明"
            :show-overflow-tooltip="false"
            min-width="260"
          >
            <template slot-scope="scope">
              {{ scope.row.remark }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="附件"
            :show-overflow-tooltip="false"
            min-width="200"
          >
            <template slot-scope="scope">
              <div v-for="(n, i) in scope.row.bidManagementFiles" :key="i" style="margin-top: 5px">
                <a
                  v-if="isShowType(n)"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  target="_blank"
                  :href="`${filepath}${n.filePath}`"
                >
                  {{ n.fileName }}
                </a>
                <span v-else class="img_txt" @click="imgIsShow(n, i)">{{ n.fileName }}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="操作"
            :show-overflow-tooltip="false"
            width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.depositReturn == 0 && editType == 'bidEdit'"
                type="warning"
                size="mini"
                @click="onBidEditFn(scope.$index, index, scope.row)"
                >退回</el-button
              >
              <el-button type="primary" size="mini" @click="showPayment(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </template>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>

    <EditDialog width="60%" :isReturn="true" @closeFn="bidDialog = false" :isShow.sync="bidDialog">
      <div slot="title" class="title">保证金是否退回</div>
      <div slot="content" class="bidContent">
        <div style="margin-bottom: 20px">
          <span style="display: inline-block; width: 100px">保证金状态：</span>
          <el-radio-group :value="bidInfo.depositReturn + ''" @input="onRadioChange">
            <el-radio label="1">退回</el-radio>
            <el-radio label="2"> 坏账 </el-radio>
          </el-radio-group>
        </div>
        <div v-if="bidInfo.depositReturn == 1">
          <span style="display: inline-block; width: 100px">退回时间：</span>
          <el-date-picker
            v-model="bidInfo.returnDate"
            :clearable="false"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="bidContent-remark">
          <span style="display: inline-block; width: 100px">特殊情况说明：</span>
          <el-input
            v-model="bidInfo.remark"
            type="textarea"
            style="display: flex; flex: 1"
          ></el-input>
        </div>
        <div class="bidContent-upload">
          <span style="display: inline-block; width: 100px">附件：</span>
          <Upload
            id="Upload"
            :type="type"
            v-if="!disabled"
            :fields="fields"
            @upload="upload"
          ></Upload>
        </div>

        <div v-for="(n, i) in bidInfo.bidManagementFiles" :key="i" class="bidContent-upload-text">
          <a
            v-if="isShowType(n)"
            style="text-decoration: revert; color: #409eff; padding-right: 8px"
            target="_blank"
            :href="`${filepath}${n.filePath}`"
          >
            {{ n.fileName }}
          </a>
          <span v-else class="img_txt" @click="imgIsShow(n, i)">{{ n.fileName }}</span>

          <i v-if="!disabled" @click="del(n, i)" class="iconfont iconerror iconCost"></i>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" @click="onBidSaveFn">保存</el-button>
        <el-button type="info" plain @click="bidDialog = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 单据详情 -->
    <CostDetailDialog
      v-if="costDialog"
      :costDialog.sync="costDialog"
      :options="costOptions"
      @returnDialogFn="costDialog = false"
    />
  </div>
</template>

<script>
export default {
  name: 'BailManage',
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    CostDetailDialog: () => import('@/components/costDetailDialog.vue'),
  },
  props: {
    //页面数据
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
    editType: {
      type: String,
      default: '',
    },
  },
  filters: {
    thousands(a) {
      if (!a) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },
  },
  data() {
    return {
      bidSecurityDeposits: [
        {
          typeTwo: null,
          sumCost: null,
          SecurityDepositsList: [
            {
              applicantName: null,
              companyType: null,
              cost: null,
              depositReturn: null,
              expenseNumber: null,
              publicSpendingPaymentApplicationId: null,
              taskDate: null,
              typeTwo: null,
              bidManagementFiles: [],
            },
          ],
        },
      ],
      companyType: [],
      dialogVisible: false,
      imgList: [],
      temporaryList: [],
      img_index: 0,
      //文件上传
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'TBBZJFJ',
      },
      type: 'TBBZJFJ',
      bidType: 'bidSecurity', //保证金附件type
      bidTyleList: [], //投标二级科目字典
      bidIndex: null, //当前点击表格
      depositReturn: [], //是否退回
      bidDialog: false,
      bidInfo: {
        depositReturn: 0,
        id: null,
        bidManagementFiles: [],
        bidManagementFileDeleteList: [],
        returnDate: '',
        remark: '',
      },
      costDialog: false, //单据详情
      costOptions: {},
    }
  },
  created() {
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BID_EARNEST_MONEY', true)
      .then(res => {
        this.depositReturn = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    disabled() {
      return this.editType === 'bidEdit' ? false : true
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.$api.dict
          .listSysDictData('cun_chu_bao_zheng_jin', true)
          .then(res => {
            this.bidTyleList = []
            this.bidSecurityDeposits = []
            res.data.forEach(v => {
              if (v.dictVal === 'lv_yue_bao_zheng_jin' || v.dictVal === 'tou_bao_zheng_jin') {
                this.bidTyleList.push(v)
                this.bidSecurityDeposits.push({
                  typeTwo: v.dictVal,
                  SecurityDepositsList: [],
                })
              }
            })
            if (val.bidSecurityDeposits) {
              const bidSecurityDeposits = val.bidSecurityDeposits.deepClone()
              // 创建一个映射以便快速访问并更新bidSecurityDeposits中的项目
              const typeTwoMap = new Map()
              this.bidSecurityDeposits.forEach(item => {
                typeTwoMap.set(item.typeTwo, item)
                item.SecurityDepositsList = item.SecurityDepositsList || [] // 确保列表已初始化
              })
              // 遍历bidSecurityDeposits，查找和更新匹配的项
              bidSecurityDeposits.forEach(v => {
                const item = typeTwoMap.get(v.typeTwo)
                if (item) {
                  // 如果存在匹配的项，则将v添加到SecurityDepositsList并更新sumCost
                  item.SecurityDepositsList.push({
                    bidManagementFiles: [],
                    ...v,
                    depositReturn: v.depositReturn,
                    bidManagementFileDeleteList: [],
                  })
                  item.sumCost = (item.sumCost || 0) + v.cost // 确保sumCost已初始化
                }
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          newVal.forEach(v => {
            if (!this.isShowType(v)) {
              this.imgList.push(v)
            }
          })
        }
      },
    },
  },
  methods: {
    async onBidSaveFn() {
      if (this.bidInfo.depositReturn != 1 && this.bidInfo.depositReturn != 2) {
        return this.$message.warning('请选择保证金状态')
      }
      const obj = {
        bidSecurityDeposits: [this.bidInfo],
        costType: this.bidInfo.costType,
      }
      await this.$api.bid.bidSecurityDeposit(obj)
      this.$message.success('操作成功！')
      this.$emit('onBidUpdateData')
      this.bidDialog = false
    },
    onBidEditFn(i, index, row) {
      this.Index = i //记录当前点击哪行
      this.bidIndex = index //当前点击那个表格
      this.bidInfo = { ...row }
      this.bidInfo.bidManagementFiles = row.bidManagementFiles || []
      this.bidInfo.bidManagementFileDeleteList = row.bidManagementFileDeleteList || []
      this.bidInfo.depositReturn = 0
      this.bidInfo.id = row.id
      this.bidInfo.returnDate = ''
      this.bidInfo.remark = ''
      this.bidDialog = true
    },
    upload(r) {
      this.temporaryList = []
      const { id } = this.bidSecurityDeposits[this.bidIndex].SecurityDepositsList[this.Index]
      r.forEach(v => {
        if (v) {
          v.type = this.type
          v.bidType = this.bidType
          v.fkId = id
          v.temporaryId = Date.now()
        }
      })
      this.bidInfo.bidManagementFiles.push(...r)
      this.temporaryList.push(...r)
    },
    del(n, i) {
      this.bidInfo.bidManagementFiles.splice(i, 1)
      if (n.id) {
        this.bidInfo.bidManagementFileDeleteList.push(n.id)
      }

      this.imgList.forEach((item, index) => {
        if (
          (item.temporaryId && n.temporaryId && item.temporaryId == n.temporaryId) ||
          item.id == n.id
        ) {
          this.imgList.splice(index, 1)
        }
      })
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
        ) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },

    onRadioChange(e) {
      this.bidInfo.depositReturn = Number(e)
      if (e == 1) {
        this.bidInfo.returnDate = Date.now()
      } else {
        this.bidInfo.returnDate = ''
      }
    },

    showPayment(row) {
      this.costOptions = row.deepClone()
      this.costDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
.header-top {
  display: flex;
  align-items: center;
  .input-money {
    display: inline-block;
    width: 200px;
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }
}
.bidContent {
  padding-left: 10px;
  .bidContent-remark {
    display: flex;
    margin: 10px 0;
  }
  .bidContent-upload {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 5px;
    #Upload {
      font-size: 28px;
      color: #409eff;
    }
  }
  .img_txt {
    text-decoration: underline;
    color: #409eff;
    &:hover {
      cursor: pointer;
    }
  }
  .bidContent-upload-text {
    display: flex;
    align-items: center;
  }
  .iconCost {
    margin-left: 10px;
    font-size: 28px;
    color: red;
  }
}
</style>
